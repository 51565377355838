import { ParcelHistoryResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Avatar, Grid, Typography } from "@mui/material";
import { AppState } from "Store";
import { t } from "i18next";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

interface IProps {
  item: ParcelHistoryResponseBody["jobHistory"][0];
}

export const HistoryItem: React.FC<IProps> = ({ item }) => {
  const language = useSelector((state: AppState) => state.auth.selectedLang);

  const endDate = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        dateStyle: "medium",
        timeStyle: "short",
      })
        .format(new Date(item.end))
        .replace(",", ""),
    [item.end, language],
  );

  const { driverAvatar, driverName } = useMemo(() => {
    if (item.employees.length == 0) {
      return { driverAvatar: undefined, driverName: "" };
    }
    const driver = item.employees[0];
    return {
      driverAvatar: driver.avatar,
      driverName: `${driver.firstName} ${driver.lastName}`,
    };
  }, [item.employees]);

  return (
    <Grid
      container
      maxWidth="60%"
      spacing={5}
      justifyContent="center"
      alignItems="center"
      marginBottom="10px"
    >
      <Grid item xs={1}>
        <Avatar src={driverAvatar} />
      </Grid>
      <Grid item xs={4}>
        <Grid item>
          <Typography variant="h6">{item.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">{driverName}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid item>
          <Typography variant="caption">
            {t("parcels.jobHistory.completed_on")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">{endDate}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
