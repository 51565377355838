import { Info, Search, Warning } from "@mui/icons-material";
import {
  alpha,
  Chip,
  InputAdornment,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  "&:hover, &:focus-within": {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    backgroundColor: alpha(theme.palette.common.white, 0),
  },
}));

interface IMapSearchField {
  currentQuery: string;
  onChange: (query: string) => void;
  parcelsFound: number;
}

export const MapSearchField: React.FC<IMapSearchField> = ({
  currentQuery: query,
  onChange,
  parcelsFound,
}) => {
  const [t] = useTranslation();

  return (
    <Stack
      sx={{ position: "absolute", left: 15, top: 15 }}
      direction="column"
      spacing={1}
    >
      <StyledTextField
        onChange={(e) => onChange(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        value={query}
        autoComplete="off"
        placeholder={t("general.fields.search") + "..."}
        type="search"
      />
      <Chip
        color={parcelsFound === 0 ? "warning" : "info"}
        icon={parcelsFound === 0 ? <Warning /> : <Info />}
        label={t("orders.create_modal.parcelsFound", {
          count: parcelsFound,
        })}
      />
    </Stack>
  );
};
