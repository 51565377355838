import { PERM } from "@ero/app-common/enums/PERM";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { OrdersRequestQuery } from "@ero/app-common/v2/routes/models/order";
import {
  assignJobEmployees,
  freeJobEmployees,
  getEmployeesV2,
  getJobsV2,
  getOrdersV2,
  printEmployeeCalendar as printEmployeeCalendarAPICall,
  rescheduleJobs,
  updateJob,
} from "Api";
import { errorToast } from "Services";
import { AppState } from "Store/store";
import { RequestBody, type UpdateCalendarJobType } from "Types";
import { downloadFile, uniqByKey } from "Utils";
import { type AxiosError } from "axios";
import moment from "moment";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  PLANNING_ACTION_TYPES,
  type IAssignMachine,
  type IFilterOrders,
  type IGetInitialCalendarRequestAction,
  type IGetJobs,
  type IPrintEmployeeCalendar,
  type IRescheduleJobs,
  type IUpdateJob,
  type IUpdateJobLock,
  type IUpdateMultipleJobs,
} from "./action-types";
import {
  addCalendarJob,
  assignMachineStatus,
  filterOrdersSuccess,
  getCalendarInitialError,
  getCalendarInitialSuccess,
  removeCalendarJob,
  rescheduleJobsSuccess,
  setError,
  setFilters,
  setJobs,
  setLoading,
  setOrders,
  setSuccess,
  updateJobLoading,
  updateJobSuccess,
  updateMultipleJobsSuccess,
} from "./actions";
import { FilterType } from "./types";

export function* getInitialCalendarSaga({
  payload,
}: IGetInitialCalendarRequestAction) {
  const { jobsDateRange, ordersDateRange } = payload;
  try {
    const requestObj = {
      jobs: call(getJobsV2, {
        limit: 999999,
        ...jobsDateRange,
      }),
      orders: call(getOrdersV2, {
        limit: 999,
        jobsDateRange: {
          start: -1,
          end: -1,
        },
        sortBy: ["date"],
        ordersDateRange,
      }),
      employees: call(getEmployeesV2, {
        type: PERM.DRIVER,
      }),
    };

    const { jobs, orders, employees } = yield all(requestObj);
    yield put(
      getCalendarInitialSuccess(
        orders.data,
        jobs.data,
        employees.data,
        jobsDateRange,
      ),
    );
    if (ordersDateRange) yield put(setFilters({ ordersDateRange }));
  } catch (error) {
    yield put(getCalendarInitialError(jobsDateRange));
    errorToast(error);
  }
}

export function* getOrdersSaga() {
  try {
    yield put(setLoading());

    const store = yield select();
    const filters: FilterType = store.planning.filters;

    const { data: orders } = yield call(getOrdersV2, {
      limit: 999,
      jobsDateRange: {
        start: -1,
        end: -1,
      },
      sortBy: ["date"],
      ...filters,
    });
    yield put(setOrders(orders));
  } catch (error) {
    errorToast(error);
  } finally {
    yield put(setSuccess());
  }
}

export function* getJobsSaga({ payload }: IGetJobs) {
  const { requestDates, dateRange, replace } = payload;

  try {
    const store = yield select();
    const planningReducer = store.planning;

    const { data: jobs } = yield call(getJobsV2, {
      ...requestDates,
      limit: 999999,
    });

    const calendarJobsForSubscribe = replace
      ? jobs
      : uniqByKey([...planningReducer.jobs, ...jobs], "_id");

    yield put(setJobs(calendarJobsForSubscribe, dateRange));
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export function* filterOrdersSaga({ payload }: IFilterOrders) {
  const { filters } = payload;
  try {
    yield put(setLoading());

    const params: OrdersRequestQuery = {
      limit: 999,
      jobsDateRange: {
        start: -1,
        end: -1,
      },
      sortBy: ["date"],
      ...filters,
    };

    const { data } = yield call(getOrdersV2, params);

    yield put(
      filterOrdersSuccess({
        orders: data,
        filters,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());
    errorToast(error);
  } finally {
    yield put(setSuccess());
  }
}

function* updateJobWorkSaga(updateData: UpdateCalendarJobType) {
  const { id, dateRange, assignedDrivers, unassignedDrivers, machine } =
    updateData;

  const body: RequestBody<"/jobs/update"> = {
    _id: id,
    start: dateRange.start,
    end: dateRange.end,
  };

  if (machine) {
    body.machine = machine;
  }

  if (assignedDrivers !== undefined && assignedDrivers?.length > 0) {
    yield call(assignJobEmployees, id, {
      ids: assignedDrivers as [number, ...number[]],
    });
  }

  if (unassignedDrivers !== undefined && unassignedDrivers?.length > 0) {
    yield call(freeJobEmployees, id, {
      ids: unassignedDrivers as [number, ...number[]],
    });
  }

  const { data } = yield call(updateJob, body);
  return data;
}

export function* rescheduleJobsSaga({ payload }: IRescheduleJobs) {
  const { data: payloadData } = payload;

  try {
    yield put(setLoading());

    const { data } = yield call(rescheduleJobs, payloadData);

    const jobs = data;
    yield put(rescheduleJobsSuccess(jobs));

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());
    //events collision
    const err = error as AxiosError<any>;
    if (err?.response?.data?.code && err?.response?.data?.code === 71) {
      errorToast("dashboard.calendar.collision");
      return;
    }
    errorToast(error);
  }
}

export function* updateJobSaga({ payload }: IUpdateJob) {
  const {
    data: { dateRange },
    revertEvent,
  } = payload;

  try {
    yield put(updateJobLoading(true));

    const store = yield select();
    const planningReducer = store.planning;

    const job = yield call(updateJobWorkSaga, payload.data);

    //We keep that hack till we convert this route to the new api
    job.order = job.order._id;

    if (job.parcel.crop) job.parcel.crop.color = job.parcel.crop.meta.color;

    if (dateRange.start === -1 && dateRange.end === -1) {
      yield put(removeCalendarJob(job));
      yield fork(getOrdersSaga);
    } else if (planningReducer.jobs.find((item) => item._id === job._id)) {
      yield put(updateJobSuccess(job));
    } else {
      yield put(addCalendarJob(job.order._id, job));
      yield fork(getOrdersSaga);
    }

    yield put(updateJobLoading(false));
  } catch (error) {
    if (revertEvent) revertEvent();
    yield put(setError());
    errorToast(error);
  }
}

export function* updateMultipleJobsSaga({ payload }: IUpdateMultipleJobs) {
  const { jobsForChange } = payload;

  try {
    yield put(setLoading());

    const changedJobs: JobResponseBody[] = [];

    for (const updateData of jobsForChange) {
      const newJob = yield call(updateJobWorkSaga, updateData);
      changedJobs.push({ ...newJob, order: newJob.order._id });
    }

    yield put(updateMultipleJobsSuccess(changedJobs));
    yield fork(getOrdersSaga);

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export function* updateJobLockSaga({ payload }: IUpdateJobLock) {
  const { data } = payload;

  try {
    yield put(setLoading());

    const result = yield call(updateJob, { _id: data.id, locked: data.locked });

    //We keep that hack till we convert this route to the new api
    const job = result.data;
    job.order = job.order._id;

    yield put(updateJobSuccess(job));

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export function* assignMachineSaga({ payload }: IAssignMachine) {
  const { id, machine } = payload;
  try {
    yield put(setLoading());
    const body = {
      machine,
      _id: id,
    };
    const { data } = yield call(updateJob, body);

    //We keep that hack till we convert this route to the new api
    data.order = data.order._id;

    yield put(updateJobSuccess(data));
    yield put(assignMachineStatus(false, true));
    yield put(setSuccess());
  } catch (error) {
    yield put(assignMachineStatus(true, false));
    yield put(setError());
    errorToast(error);
  }
}

export function* printEmployeeCalendarSaga({
  payload,
}: IPrintEmployeeCalendar) {
  const { employee, start, end } = payload;
  const planningReducer = yield select((state: AppState) => state.planning);

  try {
    yield put(setLoading());

    const { data } = yield call(
      printEmployeeCalendarAPICall,
      employee,
      start,
      end,
    );

    const selectedEmployee = planningReducer.employees.find(
      ({ _id }) => _id === employee,
    );

    const employeeName = employee
      ? `${selectedEmployee.firstName} ${selectedEmployee.lastName}`
      : "employee calendar";
    downloadFile(
      data,
      `${employeeName} ${moment(start).format("DD.MM.YYYY")} - ${moment(
        end,
      ).format("DD.MM.YYYY")}.pdf`,
    );

    yield put(setSuccess());
  } catch (error) {
    errorToast(error);
  }
}

export default function* planningSaga() {
  yield all([
    takeEvery(
      PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_REQUEST,
      getInitialCalendarSaga,
    ),
    takeEvery(PLANNING_ACTION_TYPES.GET_ORDERS, getOrdersSaga),
    takeEvery(PLANNING_ACTION_TYPES.GET_JOBS, getJobsSaga),
    takeEvery(PLANNING_ACTION_TYPES.FILTER_ORDERS, filterOrdersSaga),
    takeEvery(PLANNING_ACTION_TYPES.UPDATE_JOB, updateJobSaga),
    takeEvery(
      PLANNING_ACTION_TYPES.UPDATE_MULTIPLE_JOBS,
      updateMultipleJobsSaga,
    ),
    takeEvery(PLANNING_ACTION_TYPES.RESCHEDULE_JOBS, rescheduleJobsSaga),
    takeEvery(PLANNING_ACTION_TYPES.UPDATE_JOB_LOCK, updateJobLockSaga),
    takeEvery(PLANNING_ACTION_TYPES.ASSIGN_MACHINE, assignMachineSaga),
    takeEvery(
      PLANNING_ACTION_TYPES.PRINT_EMPLOYEE_CALENDAR,
      printEmployeeCalendarSaga,
    ),
  ]);
}
